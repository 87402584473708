<template>
    <!-- <div> -->
        <!-- :style="'top: ' + ((!user || user && user.role != 'student') ? '36px' : '0')" -->
        <b-navbar toggleable="xl" class="px-md-0" :style="'top: ' + (bannerOn ? '36px' : '0')">
            <b-container fluid>
                <Link href="/" class="navbar-brand mr-md-2">
                <b-img :src="`${routes.cdn_url}/images/logo/LOGO-NGM-color-small.png`" class="bg-white" width="180"
                    height="53" alt="Next Gen Math"></b-img>
                </Link>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <div>
                        <b-navbar-nav>
                            <li class="nav-item" :class="{active: currentPage == ''}">
                                <Link href="/" class="nav-link">Home</Link>
                            </li>
                            <b-nav-item-dropdown text="Why Next Gen Math?" block variant="primary" class="mega"
                                ref="dropdown1">
                                <b-row>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <h5 class="pl-0 pt-2">Universal Access Supports</h5>
                                        <li role="presentation" class="pl-1" :class="{active: currentPage == 'mtss'}">
                                            <Link role="menuitem" href="/mtss" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-2 pr-md-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'cubes']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Multi-Tiered System of Supports
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" class="pl-1"
                                            :class="{active: currentPage == 'english-language-development'}">
                                            <Link role="menuitem" href="/english-language-development"
                                                class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-2 pr-md-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'chalkboard']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    English Language Development
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" class="pl-1"
                                            :class="{active: currentPage == 'gifted-and-talented'}">
                                            <Link role="menuitem" href="/gifted-and-talented" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0 pl-3">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'star-shooting']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Gifted and Talented
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" class="pl-1"
                                            :class="{active: currentPage == 'special-education'}">
                                            <Link role="menuitem" href="/special-education" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'rainbow']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Special Education
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                    </b-col>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <h5 class="pl-0 pt-2">NGM Aligned to Best Practices</h5>
                                        <li role="presentation" class="pl-1" :class="{active: currentPage == 'udl'}">
                                            <Link role="menuitem" href="/udl" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'universal-access']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Universal Design for Learning
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" class="pl-1"
                                            :class="{active: currentPage == 'mastery-learning'}">
                                            <Link role="menuitem" href="/mastery-learning" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0 pl-3">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'award']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Mastery Learning
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" class="pl-1"
                                            :class="{active: currentPage == 'interdisciplinary-content'}">
                                            <Link role="menuitem" href="/interdisciplinary-content"
                                                class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'books']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Interdisciplinary Content
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                    </b-col>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <h5 class="pl-0 pt-2">NGM Success Features</h5>
                                        <li role="presentation" :class="{active: currentPage == 'dynamic-assessments'}">
                                            <Link role="menuitem" href="/dynamic-assessments" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'dice']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Dynamic Assessments
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'standards-aligned'}">
                                            <Link role="menuitem" href="/standards-aligned" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0 pl-4">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'clipboard-list']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Standards Aligned
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                    </b-col>
                                </b-row>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown text="Teaching and Learning" block variant="primary" class="mega"
                                ref="dropdown2">
                                <b-row>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <h5 class="pl-0 pt-2">Learning</h5>
                                        <li role="presentation" :class="{active: currentPage == 'blended-learning'}"
                                            class="pl-0">
                                            <Link role="menuitem" href="/blended-learning" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'users-class']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Blended Learning
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation"
                                            :class="{active: currentPage == 'individualized-learning'}" class="pl-0">
                                            <Link role="menuitem" href="/individualized-learning" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'user']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Individualized Learning
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'distance-learning'}">
                                            <Link role="menuitem" href="/distance-learning" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'laptop-house']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Distance Learning
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'procedural-fluency'}">
                                            <Link role="menuitem" href="/procedural-fluency" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'sitemap']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Procedural Fluency
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'standards-practice'}">
                                            <Link role="menuitem" href="/standards-practice" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'bullseye-arrow']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Standards Practice
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                    </b-col>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <h5 class="pl-0 pt-2">Teaching</h5>
                                        <li role="presentation"
                                            :class="{active: currentPage == 'instructional-design'}">
                                            <Link role="menuitem" href="/instructional-design" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'chalkboard-teacher']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Instructional Design
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <h5 class="pl-0 pt-0 pt-md-2">Diagnostic</h5>
                                        <li role="presentation" :class="{active: currentPage == 'analytics'}">
                                            <Link role="menuitem" href="/analytics" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'chart-bar']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Data and Analytics
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                    </b-col>
                                </b-row>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown text="Resources" block variant="primary" class="mega" ref="dropdown3">
                                <b-row>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <li role="presentation"
                                            :class="{active: currentPage == 'implementation-models'}">
                                            <Link role="menuitem" href="/implementation-models" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'book-reader']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Implementation Models
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'partner-success'}">
                                            <Link role="menuitem" href="/partner-success" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'trophy-alt']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Partner Success
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'testimonials'}">
                                            <Link role="menuitem" href="/testimonials" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'comment-alt-smile']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Testimonials
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation">
                                            <a role="menuitem" href="https://support.nextgenmath.com/hc/en-us/categories/10033631011227" class="dropdown-item" target="_blank" rel="noopener noreferrer">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'newspaper']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Blog
                                                </b-col>
                                            </b-row>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a role="menuitem" href="https://status.nextgenmath.com" class="dropdown-item" target="_blank" rel="noopener noreferrer">
                                                <b-row align-v="center">
                                                    <b-col cols="1" class="pr-0">
                                                        <font-awesome-icon class="text-primary nav-icon" :icon="['fad', 'signal-bars-strong']" />
                                                    </b-col>
                                                    <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                        Status
                                                    </b-col>
                                                </b-row>
                                            </a>
                                        </li>
                                    </b-col>
                                    <b-col cols="12" lg="4" class="mx-auto">
                                        <li role="presentation">
                                            <a  role="menuitem" 
                                                href="https://support.nextgenmath.com/hc/en-us" 
                                                class="dropdown-item" 
                                                target="_blank" 
                                                rel="noopener noreferrer" >
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'info-circle']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Support
                                                </b-col>
                                            </b-row>
                                            </a>
                                        </li>
                                        <li role="presentation" :class="{active: currentPage == 'faq'}">
                                            <Link role="menuitem" href="/faq" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'question-circle']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    FAQ
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation"
                                            :class="{active: currentPage.includes('privacy-policy')}">
                                            <Link role="menuitem" href="/privacy-policy" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'file-contract']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Privacy Agreement
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                        <li role="presentation"
                                            :class="{active: currentPage == 'contact-us'}">
                                            <Link role="menuitem" href="/contact-us" class="dropdown-item">
                                            <b-row align-v="center">
                                                <b-col cols="1" class="pr-0">
                                                    <font-awesome-icon class="text-primary nav-icon"
                                                        :icon="['fad', 'envelope']" />
                                                </b-col>
                                                <b-col cols="10" class="ml-0 ml-lg-4 pl-4 pl-md-0 pl-lg-0">
                                                    Contact Us
                                                </b-col>
                                            </b-row>
                                            </Link>
                                        </li>
                                    </b-col>
                                </b-row>
                            </b-nav-item-dropdown>
                            <!-- <li :class="{active: currentPage == 'events'}" class="nav-item">
                                <Link href="/events" class="nav-link">Events</Link>
                            </li> -->
                            <li :class="{active: currentPage == 'about'}" class="nav-item">
                                <Link href="/about" class="nav-link">About</Link>
                            </li>
                            <!-- <li :class="{active: currentPage == 'careers'}" class="nav-item">
                                <Link href="/careers" class="nav-link">Careers</Link>
                            </li> -->
                        </b-navbar-nav>
                    </div>
    
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="mr-0">
                        <li :class="{active: currentPage == 'free-trial'}" class="nav-item bg-success mr-2"
                            style="border-radius: 6px !important;">
                            <Link href="/free-trial" class="nav-link px-2" style="color: #000 !important;">Schedule my free
                            pilot!</Link>
                        </li>
                        <template v-if="!user">
                            <li :class="{active: currentPage == 'login'}" class="nav-item pb-xs-2">
                                <Link href="/login" class="nav-link">Sign In</Link>
                            </li>
                        </template>
                        <template v-else>
                            <li class="nav-item mr-3">
                                <a :href="user.isSchoolAdmin || user.isDistrictAdmin ? '/reports' : '/assignments'" class="nav-link">Sign In</a>
                            </li>
                            <!-- <li class="nav-item mr-3">
                                <a href="/grades" class="nav-link">Tiles</a>
                            </li>
                            <dashboard-dropdown></dashboard-dropdown> -->
                        </template>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
    <!-- </div> -->
</template>

<script>
    import {BCollapse, BDropdown, BDropdownItem} from 'bootstrap-vue'
    import DashboardDropdown from './DashboardDropdown.vue'
    import Banner from '../components/Banner.vue';
    
    export default {
        computed: {
            user() {
                if (this.$page) 
                    return this.$page.props.user;
                
                return this.user;
            },
            currentPage() {
                return this.$page.url.replaceAll('/', '');
            },
        },
        watch: {
            currentPage: function() {
                this.$refs.dropdown1.hide();
                this.$refs.dropdown2.hide();
                this.$refs.dropdown3.hide();
            },
        },
        props: {
            bannerOn: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            Banner,
            BCollapse,
            BDropdown,
            BDropdownItem,
            DashboardDropdown,
        }
    }
</script>

<style scoped lang="scss">
    #nav-collapse {
        justify-content: space-between;
    }
    .navbar {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-shadow: 4px 4px 12px rgba(0,0,0,0.1);
        font-size: 16px;
        margin-top: 0;
        padding: 0 1rem;
        left: 0;
        width: 100%;
        z-index: 12;

        .navbar-brand {
            margin-right: 6rem;
            padding: 0.6459rem 0;
        }

        h5 {
            color: #757575;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 1rem;
        }

        .nav-link:last-child {
            white-space: nowrap;
        }

        .nav-link, .nav-link.dropdown-toggle, .dropdown {
            color: #666 !important;
            &:hover {
                font-weight: 600;
            }
        }

        .active {
            border-bottom: 1.5px solid #2878cc !important;
        }

        .dropdown.mega {
            position: static;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &.show {
                border-bottom: none;
            
                &::v-deep .nav-link.dropdown-toggle {
                    font-weight: 450;
                    border-bottom: 1px solid #c4c4c4;
                }
            }

            ::v-deep .dropdown-menu {
                width: 64vw;
                left: calc(50% - (60vw / 2));
                margin: 0 auto;
                padding: 1.5rem;
                font-size: 16px;
                box-shadow: 4px 4px 12px rgba(0,0,0,0.1);
                border-top: 4px solid #fff;
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                .row {
                    flex-wrap: nowrap;
                }

                .dropdown-item {
                    padding: 1rem;
                }

                .dropdown-item:hover,
                .dropdown-item:active,
                .dropdown-item:hover svg,
                .dropdown-item:active svg {
                    background: #2878cc !important;
                    color: #fff !important;
                }

                .active .dropdown-item {
                    border-bottom: 1.5px solid #2878cc;
                }
            }
        }

        .nav-icon {
            font-size: 1.5rem;
        }

        @media (max-width: 1800px) {
        /* rules here apply to devices up to 1800px wide (inclusive) */
            .dropdown.mega::v-deep .dropdown-menu {
                width: 75vw;
                left: calc(50% - (75vw / 2));
            }
        }

        @media (max-width: 1400px) {
        /* rules here apply to devices up to 1400px wide (inclusive) */
            .dropdown.mega::v-deep .dropdown-menu {
                width: 85vw;
                left: calc(50% - (85vw / 2));
            }
        }

        @media (max-width: 1200px) {
        /* rules here apply to devices up to 1200px wide (inclusive) */
            .dropdown.mega::v-deep .dropdown-menu {
                width: 90vw;
                left: calc(50% - (90vw / 2));
            }
        }
        
        @media (max-width: 992px) {	
        /* rules here apply to devices from 0px to 992px (inclusive) */
            position: relative;
            padding-left: 1rem;

            .navbar-brand,
            .navbar-nav {
                padding-left: 1rem;
            }

            .dropdown.mega,
            .nav-item {
                flex-direction: column;
                justify-content: left !important;
                align-self: flex-start;
            }
            .dropdown.mega::v-deep .dropdown-menu {
                width: 85vw;
                left: calc(50% - (85vw / 2));
                box-shadow: none;
                border: none;
                align-items: flex-start;

                .row {
                    flex-wrap: wrap;
                }
            }

            .dropdown.mega.show {
                border-bottom: none;
                &::v-deep .nav-link.dropdown-toggle {
                    font-weight: 600;
                    // border-bottom: 1px solid #757575;
                }
            }

            .nav-item, 
            .nav-link {
                width: 100%;
            }

            .nav-link,
            .dropdown.mega {                
                &::v-deep .nav-link.dropdown-toggle {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                &::v-deep .dropdown-menu {
                    padding-left: 1rem;
                }
            }

            .nav-item.bg-success,
            .nav-link[href="/free-trial"] {
                width: fit-content;
            }

            a {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

        }
        @media (max-width: 768px) {	
        /* rules here apply to devices from 0px to 768px (inclusive) */
            .nav-item, 
            .nav-link {
                width: 100%;
            }

            .nav-link,
            .dropdown.mega {                
                &::v-deep .nav-link.dropdown-toggle {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                &::v-deep .dropdown-menu {
                    padding-left: 1rem;
                }
            }

            .nav-item.bg-success,
            .nav-link[href="/free-trial"] {
                width: fit-content;
            }

            a {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

        }

        @media (max-width: 576px) {	
        /* rules here apply to devices from 0px to 576px (inclusive) */
            #nav-collapse {
                padding-left: 1rem;
            }
            .navbar-nav {
                padding-left: 0;
            }
            .dropdown.mega::v-deep .dropdown-menu {
                padding-left: 0;
                padding-top: 0;
            }
        }
    }


</style>
